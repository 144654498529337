.navbar {
  background-color: lightsteelblue;
  color: white;
  width: 100%;
  height: 40px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px;
  letter-spacing: 1px;

  &--active {
    color: darkcyan;
  }

  a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 15px;
  }

  span:hover {
    color: darkslateblue;
  }
}

