.main {
  padding-top: 20px;
  margin: auto;
  width: 450px;
  text-align: left;
  font-size: 18px;
  letter-spacing: .5px;
  line-height: 20px;

  a {
    color: darkcyan;
    text-decoration: none;
    font-weight: bold;
  }

  a:hover {
    color: darkslateblue;
  }
}

