body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "Roboto", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  background-color: white;
  width: 100%;
  height: 85px;
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  letter-spacing: 1px; }

.header a {
  color: darkcyan;
  text-decoration: none;
  font-weight: bold;
  padding-top: 35px;
  padding-bottom: 50px;
  font-size: 40px; }

.navbar {
  background-color: lightsteelblue;
  color: white;
  width: 100%;
  height: 40px;
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 2px;
  letter-spacing: 1px; }
  .navbar--active {
    color: darkcyan; }
  .navbar a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 15px; }
  .navbar span:hover {
    color: darkslateblue; }

.main {
  padding-top: 20px;
  margin: auto;
  width: 450px;
  text-align: left;
  font-size: 18px;
  letter-spacing: .5px;
  line-height: 20px; }
  .main a {
    color: darkcyan;
    text-decoration: none;
    font-weight: bold; }
  .main a:hover {
    color: darkslateblue; }

.App {
  text-align: center;
  padding-top: 20px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

