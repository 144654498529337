.header {
  background-color: white;
  width: 100%;
  height: 85px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
}

.header a {
  color: darkcyan;
  text-decoration: none;
  font-weight: bold;
  padding-top: 35px;
  padding-bottom: 50px;
  font-size: 40px;
}
